import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { LanguageProvider, LANGUAGES } from "./hooks/languageContextHooks";
import "./i18n";
import { HotelDataContextHook } from "./hooks/HotelDataContextHook";
import { BrowserRouter } from "react-router-dom";
import UbicationFilterContext from "./hooks/UbicationFilterContext";
import Fallback from "./pages/fallback/Fallback";
import {
  designI,
  unitTypeI,
} from "./redux/slices/enviromentSlice/EnviromentSlice.type";
import { startDefaultConfigs } from "./config";
import {
  createTheme,
  ScopedCssBaseline,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { StyleSheetManager } from "styled-components";
import {
  LocationDropdownDesign,
  UnitType,
  WidgetConfigProps,
  WidgetDesign,
} from "./types/widgetConfig.types";
import WidgetConfigContextProvider from "./contexts/widgetConfig/WidgetConfig.context";
import { styleTags } from "./utils/style-loader";
import { setupStore } from "./redux/store";

declare global {
  interface Window {
    availRoomBookingApp: any;
  }
}

export interface WidgetProps {
  divId: string;
  environmentId: string;
  type: unitTypeI;
  unitId?: string;
  gmapToken?: string;
  defaultMargin?: boolean;
  design?: designI;
  hideRating?: boolean;
  hideLanguageSelector?: boolean;
  clientIdentifier?: string;
  paymentDefaultData?: WidgetPropsPaymentDefaultData;
  showSearchHotelInput?: boolean;
  languages: LANGUAGES[];
  shadowDom?: boolean;
  defaultLang?: LANGUAGES;
  locationDropdownDesign: LocationDropdownDesign;
}

export interface WidgetPropsPaymentDefaultData {
  name?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  disableInputs?: boolean;
}

const isProd = process.env.NODE_ENV === "production";

class availRoomBookingApp {
  constructor(params: WidgetConfigProps) {
    const { divId, environmentId, shadowDom, defaultLang, shadowStyles } =
      params;

    const searchParams = new URLSearchParams(window.location.search);
    const currentEnvironmentId = searchParams.has("environment")
      ? searchParams.get("environment")!
      : environmentId;

    startDefaultConfigs({
      environmentId: currentEnvironmentId,
    });

    const themeOptions: ThemeOptions = {
      typography: {
        fontFamily: ` "Poppins","Roboto","-apple-system","BlinkMacSystemFont","Segoe UI","Helvetica Neue","Arial","sans-serif","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
      },
      components: {
        MuiScopedCssBaseline: {
          styleOverrides: {
            root: {
              fontFamily: [
                "Poppins",
                "Roboto",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Helvetica Neue",
                "Arial",
                "sans-serif",
                "Apple Color Emoji",
                "Segoe UI Emoji",
                "Segoe UI Symbol",
              ].join(","),
            },
          },
        },
      },
    };

    const containerEl = document.getElementById(divId);

    if (!containerEl)
      throw new Error(`no container element with id ${divId} found`);

    // shadowDom

    let emotionRoot;

    if (shadowDom) {
      const shadowContainer = containerEl.attachShadow({ mode: "open" });
      emotionRoot = document.createElement("style");
      const shadowRootEl = document.createElement("div");

      //append shadow Styles

      // styled components shadow
      const styledComponentsContainerEl = document.createElement("div");

      if (shadowStyles) {
        const shadowStylesEl = document.createElement("style");
        shadowStylesEl.innerHTML = shadowStyles;
        shadowContainer.appendChild(shadowStylesEl);
      }

      // append style link
      if (isProd) {
        const styleLink = document.createElement("link");
        styleLink.setAttribute(
          "href",
          `${process.env.REACT_APP_HOSTNAME}/static/css/main.css`
        );
        styleLink.setAttribute("rel", "stylesheet");
        shadowContainer.appendChild(styleLink);
      }

      containerEl.setAttribute("data-shadowed", "style-isolated-shadowed");

      shadowContainer.append(...styleTags);
      shadowContainer.appendChild(emotionRoot);
      shadowContainer.appendChild(styledComponentsContainerEl);
      shadowContainer.appendChild(shadowRootEl);

      const cache = createCache({
        key: "css",
        prepend: true,
        container: emotionRoot,
      });

      const root = ReactDOM.createRoot(shadowRootEl);

      const muiTheme = createTheme({
        ...themeOptions,

        components: {
          ...themeOptions.components,
          MuiPopover: {
            defaultProps: {
              container: shadowRootEl,
            },
          },
          MuiPopper: {
            defaultProps: {
              container: shadowRootEl,
            },
          },
          MuiModal: {
            defaultProps: {
              container: shadowRootEl,
            },
          },
        },
      });

      root.render(
        <CacheProvider value={cache}>
          <ThemeProvider theme={muiTheme}>
            <ScopedCssBaseline>
              <StyleSheetManager target={styledComponentsContainerEl}>
                <AppProviders widgetConfig={params}>
                  <App {...params} />
                </AppProviders>
              </StyleSheetManager>
            </ScopedCssBaseline>
          </ThemeProvider>
        </CacheProvider>
      );
    } else {
      document.head.append(...styleTags);
      const root = ReactDOM.createRoot(containerEl);
      const muiTheme = createTheme(themeOptions);

      root.render(
        <ThemeProvider theme={muiTheme}>
          <ScopedCssBaseline>
            <AppProviders widgetConfig={params}>
              <App {...params} />
            </AppProviders>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    }

    const event = new CustomEvent("ReactDOMReady", {
      detail: { message: "React component is now rendered!" },
      bubbles: true,
      cancelable: false,
    });
    window.dispatchEvent(event);

    return this;
  }
}

export default availRoomBookingApp;

reportWebVitals();

const AppProviders: React.FC<{
  children: React.ReactNode;
  widgetConfig: WidgetConfigProps;
}> = ({ children, widgetConfig }) => {
  const store = setupStore();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Fallback />}>
          <WidgetConfigContextProvider widgetConfig={widgetConfig}>
            <LanguageProvider
              defaultLanguage={widgetConfig.defaultLang ?? LANGUAGES.ES}
            >
              <UbicationFilterContext>
                <HotelDataContextHook>{children}</HotelDataContextHook>
              </UbicationFilterContext>
            </LanguageProvider>
          </WidgetConfigContextProvider>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
};

const renderApp = () => {
  new availRoomBookingApp({
    divId: "availRoomBookingApp",
    environmentId: "ACDACDBF26D14E1E948D56AA66730E1A",
    gmapToken: process.env.REACT_APP_GOOGLE_MAPS_API!,
    // shadowDom: true,
    type: UnitType.MULTIUNIT,
    defaultMargin: true,
    design: WidgetDesign.DEFAULT,
    // design: "headless",
    // showSearchHotelInput: true,
    locationDropdownDesign: LocationDropdownDesign.NESTED_DROPDOWN,
    languages: [LANGUAGES.ES, LANGUAGES.EN_US],
    // paymentDefaultData: {
    //   // name: "Prueba",
    //   lastname: "Prueba",
    //   email: "prueba@prueba.com",
    //   phone: "000000000"
    //   // disableInputs: true
    // }
    // hideLanguageSelector: true
    // hideRating: true
  });
};

if (!isProd && module.hot) {
  renderApp();

  if (module.hot) {
    module.hot?.accept("./App", renderApp);
  }
}
