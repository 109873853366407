import React, { createContext, useContext, useReducer } from "react";
import {
  LANGUAGES,
  LocationDropdownDesign,
  UnitType,
  WidgetConfigProps,
  WidgetDesign,
} from "../../types/widgetConfig.types";

interface WidgetConfigContextProps {
  children: React.ReactNode;
  widgetConfig: WidgetConfigProps;
}

export interface WidgetConfigContextState extends WidgetConfigProps {}

export interface WidgetConfigContextAction {
  type: "";
  payload: any;
}

const WidgetConfigContext = createContext<WidgetConfigContextState>(
  {} as WidgetConfigContextState
);

const initialState: WidgetConfigContextState = {
  divId: "",
  environmentId: "",
  languages: [LANGUAGES.ES, LANGUAGES.EN_UK, LANGUAGES.EN_US],
  locationDropdownDesign: LocationDropdownDesign.DEFAULT,
  type: UnitType.MULTIUNIT,
  clientIdentifier: undefined,
  defaultLang: LANGUAGES.ES,
  defaultMargin: true,
  design: WidgetDesign.DEFAULT,
  gmapToken: process.env.REACT_APP_GOOGLE_MAPS_API!,
  hideLanguageSelector: false,
  hideRating: false,
  paymentDefaultData: undefined,
  shadowDom: false,
  showSearchHotelInput: false,
  unitId: undefined,
};

const widgetConfigContextReducer = (
  state: WidgetConfigContextState,
  action: WidgetConfigContextAction
) => {
  switch (action.type) {
    case "":
      return state;
    default:
      return initialState;
  }
};

const WidgetConfigContextProvider: React.FC<WidgetConfigContextProps> = ({
  children,
  widgetConfig,
}) => {
  const [state, _dispatch] = useReducer(widgetConfigContextReducer, {
    ...initialState,
    ...widgetConfig,
  });

  return (
    <WidgetConfigContext.Provider value={{ ...state }}>
      {children}
    </WidgetConfigContext.Provider>
  );
};
export default WidgetConfigContextProvider;
export const useWidgetConfigContext = () => useContext(WidgetConfigContext);
